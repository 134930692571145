import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../utils/storage';
import { axiosInstance } from '../../utils/axios';
import {
  CameraIcon,
  CheckIcon,
  PaperClipIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import AdminSidebar from '../../components/AdminSidebar';
import ComboboxSearch from '../../components/form/ComboboxSearch';
import NumberInput from '../../components/form/NumberInput';
import QRCode from 'qrcode.react';
import DeviceDetails from './DeviceDetails';
import Ocr from '../../components/Ocr';
import ComboboxSearchClient from '../../components/form/ComboboxSearchClient';
import useModalStore from '../../stores/useModalStore';
export default function DeviceForm() {
  const user = getUser();
  const [brand, setBrand] = useState("");
  const [device, setDevice] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [showOCR, setshowOCR] = useState(false);
  const [deviceImages, setDeviceImages] = useState([]);
  const [deviceFormData, setDeviceFormData] = useState({
    quantity: 1,
    stock: 1,
    counter_black: '',
    counter_color: '',
    total_counter: '',
    palette: '',
    device_brand_id: '1',
    device_model_id: '1',
    serial_no: '',
    client: null,
    user_id: user.id,
    files: [],
    created_at: new Date().toISOString().slice(0, 10)
  });
  const [deviceOptionFormData, setDeviceOptionFormData] = useState({
    finisher: false,
    'rt-lct': false,
    blue: false,
    cabinet: false,
    'no-df': false,
    spare_parts: false,
    trays: 4,
    notice_required: '0',
    notice_text: '',
    recycled_date: false
  });
  const [clients, setClients] = useState([]);
  const [deviceBrands, setDeviceBrands] = useState([]);
  const [deviceModels, setDeviceModels] = useState([]);
  const [visibleBrands, setVisibleBrands] = useState(7);
  const [error, setError] = useState(null);
  const { showModal, setShowModal, client, setClient } = useModalStore();

  const navigate = useNavigate();
  const { objID } = useParams();

  // Declare selectedItem state and initialize it as null
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClientChange = (updatedValues) => {
    setClients(updatedValues[0]);
    setClient(updatedValues[0]);
    console.log("handleClientChange", updatedValues[0]);
  };  
 
  useEffect(() => {
    if (objID) {
      // Fetch and set existing device data for edit
      fetchDeviceData();

      console.log(deviceModels);

      
      
      // if (selectedModel) {
      //   alert('ZZZ');
      //   console.log("GEX", selectedModel);
      //   // Call onSelectedItemChange with the selected model
      //   handleSelectedModelChange(selectedModel);
      // }
      // Trigger the selected item change programmatically
      // handleSelectedModelChange(fetchedDeviceModels[0]);

      setTimeout(()=> {
        // console.log("GENTI GENTI");
        // console.log(deviceModels);
      },5000);
    }
  }, [objID]);

  useEffect(() => {
    // fetchDeviceModels();
    fetchDeviceBrands();
  }, []);

  useEffect(() => {
    if (objID) {
      // Fetch and set existing device data for edit
     

      
      
      // if (selectedModel) {
      //   alert('ZZZ');
      //   console.log("GEX", selectedModel);
      //   // Call onSelectedItemChange with the selected model
      //   handleSelectedModelChange(selectedModel);
      // }
      // Trigger the selected item change programmatically
      // handleSelectedModelChange(fetchedDeviceModels[0]);

      if(deviceModels.length > 0) {
          const selectedModel = deviceModels.find((model) => model.id === deviceFormData.device_model_id);
          handleSelectedModelChange(selectedModel);
      }
     
    }
  }, [deviceModels]);

  
  useEffect(() => {
    if (
      !isNaN(deviceFormData.counter_black) ||
      !isNaN(deviceFormData.counter_color)
    ) {
      const black = parseInt(deviceFormData.counter_black);
      const color = parseInt(deviceFormData.counter_color);
      let total = 0;
      if(isNaN(black)){
        total = color;
      }
      else if(isNaN(color)){
        total = black;
      }
      else{
        total = black + color;
      }

      // Update the deviceFormData with the calculated total_counter
      setDeviceFormData((prevData) => ({
        ...prevData,
        total_counter: total
      }));
    } 
  }, [deviceFormData.counter_black, deviceFormData.counter_color]);

  // useEffect(() => {
  //   if (deviceFormData.device_brand_id) {
  //     // Fetch device models when the brand changes
  //     console.log("test");
  //     fetchDeviceModels();
  //   }
  // }, [deviceFormData.device_brand_id]);

  const handleImageCapture = (capturedImage) => {
    // Update the state with the captured image
    // setDeviceFormData((prevState) => ({
    //   ...prevState,
    //   file: capturedImage,
    // }));
  };

  const handleSelectedModelChange = (selectedItem) => {
    // Update deviceFormData with the selected device model ID
    if(selectedItem != undefined) {
      setDeviceFormData((prevData) => ({
        ...prevData,
        device_model_id: selectedItem.id,
      }));
    }
  };

  const handleSelectedClientChange = (selectedItem) => {
    // Update deviceFormData with the selected device model ID
    if(selectedItem != undefined) {
      setDeviceFormData((prevData) => ({
        ...prevData,
        client: JSON.stringify(selectedItem),
      }));

      setClient(selectedItem);
    } 
  };


  const fetchDeviceData = async () => {
    try {
      const response = await axiosInstance.get(`${window.REACT_APP_API_ENDPOINT}/api/devices/${objID}`);
      setDevice(response.data[0]);
      console.log("updateee", response.data)

      setDeviceFormData({
        quantity: response.data[0].quantity,
        stock: response.data[0].stock,
        counter_black: response.data[0].counter_black,
        counter_color: response.data[0].counter_color, 
        palette: response.data[0].palette,
        device_brand_id: response.data[0].device_brand_id, 
        device_model_id: response.data[0].device_model_id,  
        serial_no: response.data[0].serial_no,   
        user_id: response.data[0].user_id,
        created_at: response.data[0].created_at,
      });
      
      //nuka
      setBrand(response.data[0].brand_name);
      
      if(response.data[0].client_id) {
        setDeviceFormData((prevData) => ({
          ...prevData,
          client: response.data[0].client,
        }));
  
        setClient(response.data[0].client);
      }
      

      fetchDeviceModels(response.data[0].device_brand_id);
      // console.log('gent', response.data[0].device_model_id);
      setDeviceOptionFormData({
        finisher: response.data[0].device_options.finisher === 1 ? true:false,
        'rt-lct': response.data[0].device_options['rt-lct'] === 1 ? true:false,
        blue: response.data[0].device_options.blue === 1 ? true:false,
        cabinet: response.data[0].device_options.cabinet === 1 ? true:false,
        'no-df': response.data[0].device_options['no-df'] === 1 ? true:false,
        spare_parts: response.data[0].device_options.spare_parts === 1 ? true:false,
        trays: response.data[0].device_options.trays,
        notice_required: response.data[0].device_options.notice_required,
        notice_text: '',
        recycled_date: response.data[0].device_options.recycled_date === null?false:true
      });
      setError(null);

      

      
    } catch (error) {
      setError('Failed to fetch device data. Please try again.');
      console.error('Fetch device data error:', error);
    }
  };

  const handleCheckboxChange = (option) => {
    setDeviceOptionFormData((prevState) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  };

  const handleTraysChange = (e) => {
    setDeviceOptionFormData((prevData) => ({ ...prevData, trays: parseInt(e.target.value) }));
  };

  const handleCreateOptions = (device_id) => {
    // if(deviceOptionFormData.recycled_date == true){
    //     const currentDate = new Date();
    //     deviceOptionFormData.recycled_date = currentDate.toISOString();
    // }
    // else{
    //   deviceOptionFormData.recycled_date = null;
    // }
    axiosInstance
      .post(window.REACT_APP_API_ENDPOINT + '/api/device-options', {
        ...deviceOptionFormData,
        device_id: device_id,
      })
      .then((response) => {
        console.log('Device options created successfully:', response.data.message);
      })
      .catch((error) => {
        console.error('Failed to create device options:', error);
      });
  };


  const handleCreateDevice = () => {
    const formData = new FormData();
  
    // Append the file(s) to the formData
    console.log("deviceFormData.files",deviceFormData.files)
    // for (let i = 0; i < deviceFormData.files.length; i++) {
    //   formData.append('files[]', deviceFormData.files[i]);
    // }
  
    // Append other form data properties
    for (const key in deviceFormData) {
      // if (key !== 'files') {
        formData.append(key, deviceFormData[key]);
      // }
    }
  
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    axiosInstance
      .post(window.REACT_APP_API_ENDPOINT + '/api/devices', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Device created successfully:', response.data);
        setDevice(response.data);
        handleCreateOptions(response.data.id);
        setShowPDF(true);
      })
      .catch((error) => {
        console.error('Failed to create device:', error);
      });
  };
  

  const handleUpdateOptions = (id) => {
    // Add your code here to update the device options with a PUT request.
    // You can use axiosInstance.put to send the request.
    axiosInstance
      .put(window.REACT_APP_API_ENDPOINT + `/api/device-options/${id}`, {
        ...deviceOptionFormData,
        device_id: device.id
      })
      .then((response) => {
        console.log('Device options updated successfully:', response.data.message);
      })
      .catch((error) => {
        console.error('Failed to update device options:', error);
      });
  };

  const handleDeviceModelsChange = (updatedValues) => {
    setDeviceModels(updatedValues);
  };

  const handleUpdateDevice = () => {
    
    // Add your code here to update the device with a PUT request.
    // You can use axiosInstance.put to send the request.
    if(!deviceOptionFormData['blue']) {
      
      handleSelectedClientChange(undefined);

      console.log(deviceFormData);
      

      setClient(null);
    }
    axiosInstance
      .put(window.REACT_APP_API_ENDPOINT + `/api/devices/${objID}`, {
        ...deviceFormData,
      })
      .then((response) => {
        console.log('Device updated successfully:', response.data);
        setDevice(response.data);
        handleUpdateOptions(response.data.id);
        setShowPDF(true);
      })
      .catch((error) => {
        console.error('Failed to update device:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      isNaN(deviceFormData.counter_black) ||
      isNaN(deviceFormData.counter_color) ||
      isNaN(deviceFormData.palette)
    ) {
      setError('Please enter valid numbers for Counter Black, Counter Color, and Palette.');
      return;
    }

    if (objID) {
      // Fetch and set existing device data for edit
      handleUpdateDevice();
    }
    else{
      handleCreateDevice();
    }
  };

  const fetchDeviceModels = async (id) => {
    try {
      const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/device-brands/'+id+'/models');
      console.log('models', response.data);

      setDeviceModels(response.data);
      setError(null);

      // Handle the selected item if it exists in the updated list
      if (selectedItem) {
        const selectedItemInUpdatedList = response.data.find((item) => item.id === selectedItem.id);
        if (selectedItemInUpdatedList) {
          setSelectedItem(selectedItemInUpdatedList);
        } else {
          // The selected item no longer exists in the updated list, so reset it
          setSelectedItem(null);
        }
      }
    } catch (error) {
      setError('Failed to fetch devices. Please try again.');
      console.error('Fetch devices error:', error);
    }
  };

  const fetchDeviceBrands = async () => {
    try {
      const response = await axiosInstance.get(window.REACT_APP_API_ENDPOINT + '/api/device-brands?type=all');
      console.log('brands', response.data);
      setDeviceBrands(response.data);
      setError(null);
    } catch (error) {
      setError('Failed to fetch devices. Please try again.');
      console.error('Fetch devices error:', error);
    }
  };

  return (
    <AdminSidebar>
      {!showPDF && <div className="bg-white pb-8">
        <div className="bg-lightBlue px-4 sm:px-6 lg:px-8 py-4">
          <h2 className='text-textBlue text-lg flex font-bold flex items-center'><span className='mr-2'>Register a Device</span> <PlusIcon className='w-6'/></h2>
        </div>
        <div className="mt-8 flow-root px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="inline-block min-w-full py-2 align-middle">
              
                <form>
                  <h4 className="mb-4 font-bold">Brand</h4>
            
                  <div className="flex flex-wrap pb-6">
                  {deviceBrands.slice(0, visibleBrands).map((brand) => (
                    <div
                      key={brand.id}
                      className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                        deviceFormData.device_brand_id === brand.id ? 'bg-blue-200' : 'bg-white'
                      }`}
                      onClick={() => {
                        setDeviceFormData((prevData) => ({
                          ...prevData,
                          device_brand_id: brand.id,
                        }));
                        setBrand(brand.name);
                        fetchDeviceModels(brand.id);
                      }}
                    >
                      <div
                        className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                          deviceFormData.device_brand_id === brand.id ? 'bg-blue-600' : 'bg-white'
                        }`}
                      />
                      {brand.name}
                    </div>
                  ))}
                  {deviceBrands.length > visibleBrands && (
                    <button
                      onClick={() => setVisibleBrands(deviceBrands.length)}
                      className="flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 bg-white"
                    >
                      <div
                        className={`w-4 h-4 border border-textBlue rounded-full mr-2 bg-white`}
                      />
                      See More
                    </button>
                  )}
                 

                  {deviceBrands.length >= 7 && visibleBrands === deviceBrands.length && (
                    <a
                      href='/admin/device-brands'
                      className="flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 bg-white"
                    >
                      <div
                        className={`w-4 h-4 border border-textBlue rounded-full mr-2 bg-white`}
                      />
                      Other
                    </a>
                  )}
                    {deviceBrands.length >= 7 && visibleBrands === deviceBrands.length && (
                  <button
                    onClick={() => {
                      setVisibleBrands(7);
                    }}
                    className="flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 bg-white"
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 bg-white`}
                    />
                    See Less
                  </button>
                )}
                </div>
                  <h4 className="mb-4 font-bold">Details</h4>
                  <div className="flex flex-wrap lg:flex-nowrap">
                    {deviceModels.length>0 && <ComboboxSearch key={deviceModels[0].id}  title="Model" model={deviceFormData.device_model_id} brand={deviceFormData.device_brand_id} onValuesChange={handleDeviceModelsChange} values={deviceModels} onSelectedItemChange={handleSelectedModelChange} />}
                   <div className='w-full lg:w-1/2 relative'>
                    <input
                        type="text"
                        id="form_serial-no"
                        placeholder="Serial Number"
                        required
                        value={deviceFormData.serial_no}
                        onChange={(e) => {
                          setDeviceFormData((prevData) => ({ ...prevData, serial_no: e.target.value }));
                        }}
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-2"
                      />
                    <CameraIcon className='w-6 absolute top-[calc(25%-0.5rem)] right-2' onClick={()=>setshowOCR(true)}/>
                   </div>
                  </div>
                  <div className="flex flex-wrap lg:flex-nowrap mb-2">
                  
                    <input
                      type="text"
                      id="form_counter-color"
                      placeholder="Counter Color"
                      required
                      value={deviceFormData.counter_color}
                      onChange={(e) => {
                        setDeviceFormData((prevData) => ({ ...prevData, counter_color: e.target.value }));
                       
                      }}
                      className="w-full lg:w-1/2 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-2 lg:mr-2"
                    />
                      <input
                      type="text"
                      id="form_counter-black"
                      placeholder="Counter Black"
                      required
                      value={deviceFormData.counter_black}
                      onChange={(e) => {
                        setDeviceFormData((prevData) => ({ ...prevData, counter_black: e.target.value }));
                     
                      }}
                      className="w-full lg:w-1/2 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-2 lg:mr-2"
                    />
                    
                  </div>
                  {user.role == 'Admin' && <div className="flex flex-wrap lg:flex-nowrap mb-6">
                    <input
                      type="date"
                      placeholder="Date"
                      required
                      value={deviceFormData.created_at}
                      onChange={(e) => {
                        setDeviceFormData((prevData) => ({ ...prevData, created_at: e.target.value }));
                      }}
                      className="w-full lg:w-1/2 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-2"
                    />
                  </div>}
                  <div>
                    <h4 className="mb-4 font-bold">Options</h4>
                    <div className="flex flex-wrap">
                  <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['finisher'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => handleCheckboxChange('finisher')}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['finisher'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    Finisher
                  </div>
                  <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['rt-lct'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => handleCheckboxChange('rt-lct')}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['rt-lct'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    RT / LCT
                  </div>
                  
                  <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['no-df'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => handleCheckboxChange('no-df')}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['no-df'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    No DF
                  </div>
                  {user.role == 'Admin' && <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['blue'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => handleCheckboxChange('blue')}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['blue'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    Blue
                  </div>}
                </div>
                    <div className="flex flex-wrap mb-4">
                    <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['cabinet'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => handleCheckboxChange('cabinet')}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['cabinet'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    Cabinet
                  </div>
                      <div className="flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 bg-lightBlue">
                        <label for="bordered-radio-1" class="w-full py-4 text-sm font-medium text-gray dark:text-gray mr-2">Trays</label>
                        <div className="flex items-center space-x-4">
                          {[1, 2, 3, 4].map((option) => (
                            <div key={option} className="flex items-center">
                              <input
                                type="radio"
                                id={`trays-${option}`}
                                value={option}
                                checked={deviceOptionFormData.trays === option}
                                onChange={handleTraysChange}
                                className="w-4 h-4 text-blue-600 border-textBlue focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-textBlue focus:ring-2 dark:bg-textBlue dark:border-textBlue"
                              />
                              <label htmlFor={`trays-${option}`} className="ml-2 text-sm font-medium text-gray dark:text-gray">
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="w-fit mb-6">
                      <NumberInput
                        title={"Quantity"}
                        value={deviceFormData.quantity}
                        onChange={(value) =>
                          setDeviceFormData((prevData) => ({
                            ...prevData,
                            quantity: parseInt(value),
                          }))
                        }
                      />
                      <NumberInput
                        title={"Stock"}
                        value={deviceFormData.stock}
                        onChange={(value) =>
                          setDeviceFormData((prevData) => ({
                            ...prevData,
                            stock: parseInt(value),
                          }))
                        }
                      />
                      <input
                        type="text"
                        placeholder="Palette"
                        required
                        value={deviceFormData.palette}
                        onChange={(e) => {
                          setDeviceFormData((prevData) => ({ ...prevData, palette: e.target.value }));
                        }}
                        className="w-full lg:w-1/2 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="flex">
                      <div class="flex items-center mb-4 mr-8">
                      {user.role == 'Admin' &&deviceOptionFormData['blue'] && <ComboboxSearchClient title="Model" client={client} onValuesChange={handleClientChange} values={clients} onSelectedItemChange={handleSelectedClientChange} />}
                      </div>
                    </div>
                  </div>
                  


                  

                  <div className="mb-6">
                    <h4 className="mb-2">Is a notice required for your device?</h4>
                    <div className="flex">
                      <div class="flex items-center mb-4 mr-8">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value="1"
                          name="default-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          checked={deviceOptionFormData.notice_required == 1}
                          onChange={(e) => {
                            setDeviceOptionFormData((prevData) => ({ ...prevData, notice_required: e.target.value }));
                          }}
                        />
                        <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                      </div>
                      <div class="flex items-center mb-4">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value="0"
                          name="default-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          checked={deviceOptionFormData.notice_required == 0}
                          onChange={(e) => {
                            setDeviceOptionFormData((prevData) => ({ ...prevData, notice_required: e.target.value }));
                          }}
                        />
                        <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                      </div>
                    </div>
                    {deviceOptionFormData.notice_required == 1 && <div>
                      <textarea
                        required
                        value={deviceOptionFormData.notice_text}
                        onChange={(e) => {
                          setDeviceOptionFormData((prevData) => ({ ...prevData, notice_text: e.target.value }));
                        }}
                        className="h-28 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-2"
                      />
                    </div>}
                  </div>
                  <div className="flex flex-wrap">
                  <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['recycled_date'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => {
                      setDeviceOptionFormData((prevState) => ({
                        ...prevState,
                        recycled_date: !prevState.recycled_date,
                        spare_parts: false, // Deselect the other option
                      }));
                    }}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['recycled_date'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    Recycled
                  </div>
                  <div
                    className={`flex items-center px-4 border border-textBlue rounded dark:border-textBlue h-8 mr-2 mb-2 ${
                      deviceOptionFormData['spare_parts'] ? 'bg-blue-200' : 'bg-white'
                    }`}
                    onClick={() => {
                      setDeviceOptionFormData((prevState) => ({
                        ...prevState,
                        recycled_date: false, // Deselect the other option
                        spare_parts: !prevState.spare_parts,
                      }));
                    }}
                  >
                    <div
                      className={`w-4 h-4 border border-textBlue rounded-full mr-2 ${
                        deviceOptionFormData['spare_parts'] ? 'bg-blue-600' : 'bg-white'
                      }`}
                    />
                    Spare Parts
                  </div>
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {error}
                </p>
                  <div className="flex w-full justify-end">
                    <button
                      type="submit"
                      className="rounded-md bg-bluePrimary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bluePrimary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={handleSubmit}
                    >
                      Generate Barcode
                    </button>
                  </div>
                </form>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {error}
                </p>
                {showOCR&&<Ocr onSerialNumber={(number)=> setDeviceFormData((prevData) => ({
                            ...prevData,
                            serial_no: number,
                          }))}
                          onCounterBlack={(number)=> setDeviceFormData((prevData) => ({
                            ...prevData,
                            counter_black: number,
                          }))}
                          onCounterColor={(number)=> setDeviceFormData((prevData) => ({
                            ...prevData,
                            counter_color: number,
                          }))}
                          onClose={()=>setshowOCR(false)}
                          onImageCapture={handleImageCapture}
                          brand={brand}
                          onDeviceImages={(files)=> setDeviceFormData((prevData) => ({
                            ...prevData,
                            files: files,
                          }))}/>}
            </div>
          </div>
        </div>
      </div>}
      {(device && showPDF) && <DeviceDetails deviceID={device.id} printOnLoad={true} />}
      
    </AdminSidebar>
  );
}